import GraniteError from 'granite-admin/utils/granite-error';
import userAPI from 'granite-admin/accounts/gateways/user-api';
import accountsUserAPI from 'accounts/gateways/user_api';
import { TWO_FA_LAYER_EVENTS } from './events';
//add docs
async function submitToken(eventEmitter, token, email) {
  try {
    await userAPI.submitToken(token, email);
    eventEmitter.emit(TWO_FA_LAYER_EVENTS.SUBMIT_TOKEN_SUCCESS);
  } catch (error) {
    const err = new GraniteError(error);
    eventEmitter.emit(TWO_FA_LAYER_EVENTS.SUBMIT_TOKEN_FAILURE, err?.errors?.title);
  }
}
async function resendOtp(eventEmitter, payload, setErrors) {
  try {
    await accountsUserAPI.resendOtp(payload);
    eventEmitter.emit(TWO_FA_LAYER_EVENTS.RESEND_OTP_SUCCESS);
  } catch (error) {
    const err = new GraniteError(error);
    eventEmitter.emit(TWO_FA_LAYER_EVENTS.RESEND_OTP_FAILURE, { err, setErrors });
  }
}

export { submitToken, resendOtp };
