// Lib imports
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, Box } from 'grommet';
import { useDispatch } from 'react-redux';

// Core imports
import {
  setAuthToken,
  setSsoLogin,
  setMyOrganisations,
  getAuthToken,
  setTenantAccess,
  // resetAuthToken,
  resetMyOrganisations,
  setOrganisation,
  getTenantAccess,
} from 'granite-admin/utils/auth-singleton';
import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import SplitLayout from 'granite-admin/core/components/SplitLayout';
import Loader from 'granite-admin/core/components/Loader';
import { getMyProfile } from 'accounts/controllers/user';

// Application imports
import { loginClicked } from 'granite-admin/accounts/controllers/user';
import LoginForm from 'granite-admin/pages/login/components/LoginForm';
import { LOGIN_EVENTS } from 'granite-admin/accounts/controllers/constants';
import useQuery from 'granite-admin/utils/useQuery';
import { checkUserAgent } from 'granite-admin/utils/functions';
import { mainDomain } from 'utils/miscellaneous';
import { getCookie } from 'granite-admin/utils/storage';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';
import { deleteCookie } from 'granite-admin/utils/storage';

import LoginAsLayer from './components/LoginLayer';

import { env } from 'granite-admin/env';

import customLogo from 'assets/logo.png';
// import { checkMobileDevice } from 'utils/miscellaneous';

const projectName = env.REACT_APP_PROJECT_NAME || process.env.REACT_APP_PROJECT_NAME || 'Granite';
const msg = `Sign in to your ${projectName} Account`;
const sso_ios = env.REACT_APP_SSO_IOS_URL || process.env.REACT_APP_SSO_IOS_URL || '';
const appleStore = env.REACT_APP_APPLE_STORE_URL || process.env.REACT_APP_APPLE_STORE_URL || '';
const sso_android = env.REACT_APP_SSO_ANDROID_URL || process.env.REACT_APP_SSO_ANDROID_URL || '';
const android_package = env.REACT_APP_ANDROID_PACKAGE || process.env.REACT_APP_ANDROID_PACKAGE || '';

const handleBackToBoardRedirect = (query, dispatch, navigate) => {
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  if (query.tenantAccess) {
    setTenantAccess(query.tenantAccess);
  } else {
    setTenantAccess(false);
  }
  dispatch({
    type: 'accounts/workspace/RESET_WORKSPACE',
  });
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: {},
  });
  dispatch({
    type: 'organisations/UPDATE_ORGANISATIONS',
    data: null,
  });
  dispatch({
    type: 'accounts/sidebar/RESET_SIDEBAR',
    data: [],
  });
  resetMyOrganisations();
  if (getTenantAccess()) setTenantAccess(false);
  setTimeout(() => navigate('/adminlogin'), 0);
};

const handlePtaRedirect = (query, dispatch, navigate, defaultThemeConfigs, getSavedThemeConfigs, route) => {
  const org = JSON.parse(getCookie('organisationData'));
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  setMyOrganisations(org);
  if (query.tenantAccess) {
    setTenantAccess(query.tenantAccess);
  } else {
    setTenantAccess(false);
  }

  getMyProfile()
    .then(response => {
      dispatch({
        type: 'accounts/user/UPDATE_PROFILE',
        data: response,
      });
    })
    .catch(e => {
      console.log(e);
    });
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: org,
  });
  dispatch({
    type: 'organisations/UPDATE_ORGANISATIONS',
    data: [org],
  });
  dispatch({
    type: 'accounts/sidebar/RESET_SIDEBAR',
    data: [],
  });
  dispatch({
    type: 'common/themeConfigs/UPDATE_THEME_CONFIGS',
    data: defaultThemeConfigs,
  });

  if (route) {
    navigate('/' + route);
  } else navigate('/adminlogin');
  getSavedThemeConfigs();
};

const Login = ({ sideContent, bannerPadding }) => {
  const { query, navigate } = useQuery();
  const { errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const [organisation, setOrganisation] = useState([]);
  const config = useContext(ConfigContext);
  const theme = useContext(ThemeContext);
  const { SideContent } = config?.sideContentSettings || '';
  const { sideProps, logoWidth, sideWidth, configMainPropsLogin } = theme?.sideContentSettings || '';
  const dispatch = useDispatch();
  const [loginAsLayer, setLoginAsLayer] = useState(false);
  const { defaultThemeConfigs, getSavedThemeConfigs } = useThemeConfigs();
  const loader = query.redirectDomain || query.tokenLogin || (query.access_token && query.access_token !== 'none');

  useEffect(() => {
    if (!query || (query.handlePta !== 'yes' && query.redirectDomain !== 'yes')) {
      deleteCookie('organisationData', mainDomain);
      deleteCookie('BAorigin', mainDomain);
      deleteCookie('redirectDomain', mainDomain);
      // deleteAllCookies(mainDomain);
    }

    if (query && query.tokenLogin === 'yes' && query.access_token) {
      setTenantAccess(query.tenantAccess || false);
      setAuthToken(query.access_token);
      navigate('/adminlogin');
    } else if (query && query.redirectDomain === 'yes' && query.access_token) {
      handleDomainRedirect(query, dispatch, navigate, query.route);
    } else if (query && query.backToBoard === 'yes' && query.access_token) {
      handleBackToBoardRedirect(query, dispatch, navigate);
    } else if (query && query.handlePta === 'yes' && query.access_token) {
      handlePtaRedirect(query, dispatch, navigate, defaultThemeConfigs, getSavedThemeConfigs, query.route);
    } else if (query && query?.access_token?.toLowerCase() === 'none' && query?.error) {
      errorToast(query.error || 'Unable to Sign in, please try again!');
    } else if (query && query.access_token) deepLinkApp(query.access_token, navigate, query.organisation);
  }, [defaultThemeConfigs, dispatch, errorToast, getSavedThemeConfigs, navigate, query]);

  const goToDashboard = useCallback(() => navigate('/adminlogin'), [navigate]);
  const goToOtp = useCallback(
    eventData =>
      navigate('/otp-submit', {
        state: eventData,
      }),
    [navigate],
  );

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          if (event.data) {
            goToOtp(event.data);
          } else {
            goToDashboard();
          }
          break;
        case LOGIN_EVENTS.LOGIN_FAILURE:
          setOrganisation(event?.data?.organisations);
          errorToast(event?.data?.title || LOGIN_MESSAGES.LOGIN_FAILURE);
          break;
        case LOGIN_EVENTS.USER_NOT_EXIST:
          errorToast(LOGIN_MESSAGES.USER_NOT_EXIST);
          break;
        case 'ONLY_PARENT_LOGIN':
          errorToast(
            'The credentials being used do not belong to an admin, please try logging in to the Parent Portal below',
          );
          break;
        case 'ONLY_BA_ACCESSING_WRONG_ORG':
          errorToast('User not belongs to this organization');
          break;
        case 'BA_CAN_BE_EMPLOYEE': {
          const auth = getAuthToken();
          // if (event.data?.url)
          setLoginAsLayer({ authToken: auth, URL: event.data?.url, mappedOrgId: event.data?.orgId });
          // else {
          // resetAuthToken();
          // setTenantAccess(true);
          // setAuthToken(auth);
          // goToDashboard();
          // }
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, goToDashboard, goToOtp, errorToast]);

  const closeLayer = () => {
    setLoginAsLayer(false);
    removeAuthToken(); //in case user doesn't select any option from the loginAsLayer, delete jwtToken
  };

  return (
    <>
      {loader ? (
        <Box align="center" height="100%" justify="center">
          <Loader />
        </Box>
      ) : (
        <SplitLayout
          mainContent={
            <LoginForm
              loginClicked={loginClicked}
              organisation={organisation}
              setOrganisation={setOrganisation}
              eventEmitter={eventEmitter}
              config={config}
              msg={msg}
            />
          }
          sideContent={SideContent ? <SideContent /> : sideContent}
          bannerPadding={bannerPadding}
          sideProps={sideProps}
          logoWidth={logoWidth}
          // formPad={formPad}
          sideWidth={sideWidth}
          mainProps={configMainPropsLogin ?? { style: { marginTop: '3%' } }}
          customLogo={customLogo}
        />
      )}
      {loginAsLayer && (
        <LoginAsLayer closeLayer={closeLayer} goToDashboard={goToDashboard} loginAsLayer={loginAsLayer} />
      )}
    </>
  );
};

const deepLinkApp = (token, navigate, orgId) => {
  const agent = checkUserAgent();
  if (agent === 'IOS') {
    window.location.href = `${sso_ios}${token}`;
    setTimeout(function () {
      // If the user is still here, open the App Store
      if (!document.hidden) window.location.href = appleStore;
    }, 5000);
  } else if (agent === 'ANDROID') window.location.href = `${sso_android}${token}${android_package}`;
  else {
    setAuthToken(token);
    setSsoLogin();
    if (orgId) {
      setOrganisation(orgId);
      setTenantAccess(true);
    }
    navigate('/adminlogin');
  }
};

const handleDomainRedirect = (query, dispatch, navigate, route) => {
  const org = JSON.parse(getCookie('organisationData'));
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  setMyOrganisations(org);
  if (query.tenantAccess) {
    setTenantAccess(query.tenantAccess);
  } else {
    setTenantAccess(false);
  }
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: org,
  });
  dispatch({
    type: 'organisations/UPDATE_ORGANISATIONS',
    data: [org],
  });
  dispatch({
    type: 'accounts/sidebar/RESET_SIDEBAR',
    data: [],
  });
  setTimeout(() => {
    if (route) {
      navigate('/' + route);
    } else navigate('/adminlogin');
  }, 0);
};

Login.defaultProps = {
  bannerPadding: 'large',
  sideContent: null,
};

Login.propTypes = {
  bannerPadding: PropTypes.string,
  sideContent: PropTypes.node,
};

export default Login;
