import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Button, TextInput, Anchor, Heading, Spinner } from 'grommet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import LOGIN_MESSAGES from 'granite-admin/messages/login';
import FormField from 'granite-admin/core/components/FormField';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import { useToast } from 'granite-admin/core/components/Toast';
import useQuery from 'granite-admin/utils/useQuery';

import { isSSOBtnVisible } from 'accounts/controllers/user';
import { LOGIN_EVENTS } from 'accounts/controllers/constants';

const getEmailQuery = async inputRef => {
  let value = inputRef?.current?.value;
  if (!value) return '';
  let emailValidity = Yup.string().email();
  let isEmailValid = await emailValidity.isValid(value);
  if (isEmailValid) return `email=${value}`;
  return '';
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
    .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
    .email(LOGIN_MESSAGES.EMAIL_INVALID)
    .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
  password: Yup.string()
    .min(6, LOGIN_MESSAGES.PASS_TOO_SHORT)
    .max(50, LOGIN_MESSAGES.PASS_TOO_LONG)
    .required(LOGIN_MESSAGES.PASS_REQUIRED),
});

const parentMainUrl = process.env.REACT_APP_PARENT_MAIN_URL;
const konectSsoUrl = process.env.REACT_APP_KONECT_SSO_URL;
const currentDomain = window.location;
const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';
let ssoRedirectUrl = `${konectSsoUrl}?current_url=${currentDomain.origin}/parent/login/&school=${subdomain}`;

const Loginform = ({ eventEmitter, loginClicked }) => {
  const { errorToast } = useToast();
  const { query } = useQuery();
  const [ssoVisible, setSsoVisible] = useState('');
  const [ssoLabel, setSsoLabel] = useState('SSO Login');
  const [loading, setLoading] = useState(true);

  const inputRef = useRef();
  const navigate = useNavigate();
  const handleForgotPassword = async () => {
    const query = await getEmailQuery(inputRef);
    navigate(`/parent/forgot-password?${query}`);
  };

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.SSO_SUCCESS:
          if (event.data?.results?.length) {
            event.data.results.forEach(i => {
              if (i['is_sso_enabled']) setSsoVisible(i['is_sso_enabled']?.value);
              if (i['forgerock_login_text']) setSsoLabel(i['forgerock_login_text']?.value || 'SSO Login');
            });
          }
          setLoading(false);
          break;
        case LOGIN_EVENTS.SSO_FAILURE:
          setSsoVisible('');
          setLoading(false);
          errorToast(event?.data || 'Unable to fetch SSO details');
          break;
        case LOGIN_EVENTS.SSO_FAILURE:
          setSsoVisible('');
          setLoading(false);
          errorToast(event?.data || 'Unable to fetch SSO details');
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast]);

  useEffect(() => {
    if (subdomain && currentDomain.href !== parentMainUrl) {
      const fetch = async () => {
        await isSSOBtnVisible(eventEmitter, subdomain);
      };
      fetch();
    }
    if (query?.error) errorToast(query?.error.split('/?')[0]);
  }, [eventEmitter, errorToast, query]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      await loginClicked(eventEmitter, values);
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        screen: 'parent',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <>
          <Heading level={3}>Parent Portal Login</Heading>
          <Heading level={5} size="large" color="dark-2">
            Sign in to your RollCall Account
          </Heading>
          <form onSubmit={handleSubmit}>
            <Text as="div" color="status-critical">
              {errors.title}
            </Text>
            {/** FIXME: Move strings to messages */}
            <FormField
              name="email"
              label={LOGIN_MESSAGES.EMAIL_LABEL}
              error={touched.email && errors.email}
              normal={true}
            >
              <TextInput
                ref={inputRef}
                type="email"
                name="email"
                onChange={e => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.email}
                style={{ marginTop: '0px' }}
              />
            </FormField>
            <FormField
              name="password"
              label={LOGIN_MESSAGES.PASS_LABEL}
              required
              error={touched.password && errors.password}
              normal={true}
            >
              <PasswordInput
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type="password"
                style={{ marginTop: '0px' }}
              />
            </FormField>
            <Box direction="row" gap="small" margin={{ top: 'medium' }}>
              <Button
                type="submit"
                alignSelf="start"
                label={
                  isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting}
                primary
                color="secondary"
                style={{ borderRadius: '4px' }}
              />
              {loading && (
                <Box
                  align="center"
                  style={{ display: ssoVisible ? 'none' : '' }}
                  direction="row"
                  margin={{ left: 'medium' }}
                >
                  <Spinner
                    border={[
                      {
                        side: 'horizontal',
                        color: 'brand',
                        size: 'large',
                        style: 'inset',
                      },
                    ]}
                  />
                </Box>
              )}
              <Button
                alignSelf="end"
                label={ssoLabel}
                disabled={isSubmitting}
                primary
                style={{ display: !ssoVisible ? 'none' : '', borderRadius: '4px' }}
                color="#1c2b36"
                onClick={() => window.location.replace(ssoRedirectUrl)}
              />
            </Box>

            <Box align="start" margin={{ vertical: 'small' }}>
              <Anchor
                onClick={handleForgotPassword}
                as="span"
                label={LOGIN_MESSAGES.FORGOT_PASS_LINK_LABEL}
                style={{ fontSize: '12px' }}
              />
            </Box>
          </form>
        </>
      )}
    </Formik>
  );
};

Loginform.propTypes = {
  eventEmitter: PropTypes.object,
  loginClicked: PropTypes.func,
};

export default Loginform;
