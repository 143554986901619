import React, { useMemo, useEffect, useCallback, useContext } from 'react';
import { Box, ThemeContext } from 'grommet';

import { LOGIN_EVENTS } from 'granite-admin/accounts/controllers/constants';
import EventEmitter from 'granite-admin/utils/event-emitter';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import { useToast } from 'granite-admin/core/components/Toast';
import { setAuthToken, setSsoLogin, setOrganisation, setTenantAccess } from 'granite-admin/utils/auth-singleton';
// Application imports
import { checkUserAgent } from 'granite-admin/utils/functions';
// import { loginClicked } from 'granite-admin/accounts/controllers/user';
import useQuery from 'granite-admin/utils/useQuery';
import SplitLayout from 'granite-admin/core/components/SplitLayout';
import Loader from 'granite-admin/core/components/Loader';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';

import { loginClicked } from 'accounts/controllers/user';

import LoginForm from './components/LoginForm';
import customLogo from 'assets/logo.png';
// import { checkMobileDevice } from 'utils/miscellaneous';

const sso_ios = process.env.REACT_APP_SSO_IOS_URL || '';
const appleStore = process.env.REACT_APP_APPLE_STORE_URL || '';
const androidStore = process.env.REACT_APP_ANDROID_STORE_URL || '';
const sso_android = process.env.REACT_APP_SSO_ANDROID_URL || '';
const android_package = process.env.REACT_APP_ANDROID_PACKAGE || '';

const ParentLogin = () => {
  const { navigate, query } = useQuery();
  const loader = query.redirectDomain || query.tokenLogin || (query.access_token && query.access_token !== 'none');
  const { errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const goToParent = useCallback(() => navigate('/parent'), [navigate]);
  const goToOtp = useCallback(
    eventData =>
      navigate('/parent/otp-submit', {
        state: eventData,
      }),
    [navigate],
  );
  const currentDomain = window.location;
  const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';

  const config = useContext(ConfigContext);
  const theme = useContext(ThemeContext);
  const { SideContent } = config?.sideContentSettings || '';
  const { sideProps, logoWidth, sideWidth, configMainPropsLogin } = theme?.sideContentSettings || '';

  const deepLinkApp = useCallback(
    (token, orgId, refresh, subdomain) => {
      const agent = checkUserAgent();
      if (agent === 'IOS') {
        window.location.href = `${sso_ios}${token}/orgId=${orgId}/refresh_token=${refresh}/school=${subdomain}`;
        setTimeout(function () {
          // If the user is still here, open the App Store
          if (!document.hidden) window.location.href = appleStore;
        }, 5000);
      } else if (agent === 'ANDROID') {
        window.location.href = `${sso_android}${token}/orgId=${orgId}/refresh_token=${refresh}/school=${subdomain}${android_package}`;
        setTimeout(function () {
          // If the user is still here, open the Play Store
          if (!document.hidden) window.location.href = androidStore;
        }, 5000);
      } else {
        setAuthToken(token);
        setSsoLogin();
        setTenantAccess(true);
        setOrganisation(orgId);
        goToParent();
      }
    },
    [goToParent],
  );

  useEffect(() => {
    if (query && query.access_token && query.organisation && query.refresh_token)
      deepLinkApp(query.access_token, query.organisation, query.refresh_token, subdomain);
    // else if (checkMobileDevice()) navigate('/');
  }, [query, navigate, deepLinkApp]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          if (event.data) {
            goToOtp(event.data);
          } else {
            goToParent();
          }
          break;
        case LOGIN_EVENTS.LOGIN_FAILURE:
          //   setOrganisation(event.data.organisations);
          errorToast(event?.data?.title || LOGIN_MESSAGES.LOGIN_FAILURE);
          break;
        case LOGIN_EVENTS.USER_NOT_EXIST:
          errorToast(LOGIN_MESSAGES.USER_NOT_EXIST);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, goToParent]);

  return (
    <>
      {loader ? (
        <Box align="center" height="100%" justify="center">
          <Loader />
        </Box>
      ) : (
        <SplitLayout
          mainContent={<LoginForm loginClicked={loginClicked} eventEmitter={eventEmitter} config={config} />}
          sideContent={<SideContent />}
          bannerPadding={'large'}
          sideProps={sideProps}
          logoWidth={logoWidth}
          // formPad={formPad}
          sideWidth={sideWidth}
          mainProps={configMainPropsLogin ?? { style: { marginTop: '3%' } }}
          customLogo={customLogo}
        />
      )}
    </>
  );
};
export default ParentLogin;
